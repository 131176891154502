import React from "react"
import { Fade } from "react-awesome-reveal"

const AboutDeveloperNumbers = () => {
    return (
        <section className="about-developer-numbers">
            <div className="container">
                <div className="row about-developer-numbers__holder">
                        <div className="col-12 col-sm-6 col-lg-3 about-developer-numbers__item">
                            <Fade delay={150} direction={"top"} triggerOnce>
                                <p className="big-number item__number">
                                    385
                                </p>
                                <h3 className="item__desc small">
                                    zrealizowanych <br />
                                    projektów
                                </h3>
                            </Fade>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-3 about-developer-numbers__item">
                            <Fade delay={250} direction={"top"} triggerOnce>
                                <p className="big-number item__number">
                                    187
                                </p>
                                <h3 className="item__desc small">
                                    zmodernizowanych <br />
                                    budynków
                                </h3>
                            </Fade>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-3 about-developer-numbers__item">
                            <Fade delay={500} direction={"top"} triggerOnce>
                                <p className="big-number item__number">
                                    39
                                </p>
                                <h3 className="item__desc small">
                                    lat doświadczenia <br />
                                    w branży
                                </h3>
                            </Fade>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-3 about-developer-numbers__item">
                            <Fade delay={750} direction={"top"} triggerOnce>
                                <p className="big-number item__number">
                                    132
                                </p>
                                <h3 className="item__desc small">
                                    zatrudnionych <br />
                                    specjalistów
                                </h3>
                            </Fade>
                        </div>
                </div>
            </div>
        </section>
    )
}
export default AboutDeveloperNumbers