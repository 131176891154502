import React from "react"
import { Fade } from "react-awesome-reveal"

const AboutDeveloperInfoOne = () => {
    return (
        <section className="about-developer-info-one">
            <div className="container">
                <div className="row about-developer-info-one__holder">
                    <div className="col-12 col-lg-5 col-xxl-5 about-developer-info-one__image">
                        <Fade delay={150} direction={"left"} triggerOnce>
                            <div className="image__holder image-shadow" style={{ backgroundImage: `url('${ require('../../assets/img/about-developer1.jpg') }')` }}></div>
                        </Fade>
                    </div>
                    <div className="col-12 col-lg-6 col-xxl-5 offset-lg-1 about-developer-info-one__content">
                        <Fade delay={250} direction={"right"} triggerOnce>
                            <h2 className="content__title mb-60">
                                Jesteśmy DES.<br />
                                Patrzymy w przyszłość, nie zapominając o przeszłości
                            </h2>
                        </Fade>
                        <Fade delay={500} direction={"right"} triggerOnce>

                            <p className="content__text mb-40">
                                Od 1981 roku specjalizujemy się w pracach budowlano-konserwatorskich.
                                Za naszym doświadczeniem stoi ponad 360 zrealizowanych z sukcesem projektów.
                            </p>
                        </Fade>
                        <Fade delay={750} direction={"right"} triggerOnce>
                            <p className="content__text mb-40">
                                Zakres naszej działalności obejmuje kompleksowe remonty i adaptacje obiektów 
                                zabytkowych w pełnym zakresie: od prac konstrukcyjno - budowlanych poprzez prace 
                                budowlane zewnętrzne, prace konserwatorskie, po prace związane z adaptacją i 
                                wykończeniem wnętrz. Do Naszych specjalności należy zaliczyć remonty elewacji 
                                tynkowych, remonty elewacji ceglanych remonty dachów oraz wykonanie izolacji 
                                pionowych i poziomych, konserwację budownictwa drewnianego, renowację.
                            </p>
                        </Fade>
                        <Fade delay={1000} direction={"right"} triggerOnce>
                            <p className="content__text mb-30">
                                Różnorodne umiejętności i specjalizacje, które posiadają zatrudnione osoby sprawiają, 
                                że gwarantujemy najwyższy poziom naszych usług.
                            </p>
                        </Fade>
                        <Fade delay={1250} direction={"right"} triggerOnce>
                            <div className="content__bottom">
                                <img data-src={ require('../../assets/img/logo-des.png') } className="lazyload" alt=""/>
                                <a href="https://www.des.krakow.pl/" target="_blank" rel="noreferrer" className="content__link">
                                    des.krakow.pl
                                </a>
                            </div>
                        </Fade>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default AboutDeveloperInfoOne