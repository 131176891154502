import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Banner from "../components/banner"
import AboutDeveloperInfoOne from "../components/aboutDeveloper/aboutDeveloperInfoOne"
import AboutDeveloperNumbers from "../components/aboutDeveloper/aboutDeveloperNumbers"
import AboutDeveloperInfoTwo from "../components/aboutDeveloper/aboutDeveloperInfoTwo"

const AboutDeveloper = () => (
	<Layout>
		<SEO title="O deweloperze" />
        <Banner title="O deweloperze" />
        <AboutDeveloperInfoOne />
        <AboutDeveloperNumbers />
        <AboutDeveloperInfoTwo />
	</Layout>
)

export default AboutDeveloper
