import React from "react"
import { Fade } from "react-awesome-reveal"

const AboutDeveloperInfoTwo = () => {
    return (
        <section className="about-developer-info-two">
            <div className="container">
                <div className="row about-developer-info-two__holder">
                    <div className="col-12 col-lg-6 col-xxl-5 offset-xxl-1 about-developer-info-two__content">
                        <Fade delay={150} direction={"left"} triggerOnce>
                            <h2 className="content__title mb-40">
                                Historia
                            </h2>
                        </Fade>
                        <Fade delay={250} direction={"left"} triggerOnce>
                            <p className="content__text mb-40">
                                Firma budowlano – konserwatorska „DES” <span className="semi-bold">powstała w 1981</span> roku przy Gminnej 
                                Spółdzielni w Michałowicach. Od ponad 30 lat specjalizujemy się w pracach budowlano 
                                -konserwatorskich w województwach Małopolskim i Śląskim. Założyciel firmy <br />
                                <span className="semi-bold">Henryk Dowgier</span> dba by o zabytki troszczył się zespół wykwalifikowanych pracowników.
                            </p>
                        </Fade>
                        <Fade delay={500} direction={"left"} triggerOnce>
                            <p className="content__text">
                                Za zasługi na rzecz ratowania zabytków polskiej kultury, założyciel został wyróżniony 
                                przez prezydenta RP <span className="semi-bold">Orderem Odrodzenia Polski.</span>
                            </p>
                        </Fade>
                    </div>
                    <div className="col-12 col-lg-6 col-xxl-5 offset-xxl-1 about-developer-info-two__images">
                        <Fade delay={150} direction={"right"} triggerOnce>
                            <img data-src={ require('../../assets/img/about-developer2.jpg') } alt="" className="images__first image-shadow lazyload"/>
                        </Fade>
                        <Fade delay={250} direction={"right"} triggerOnce>
                            <img data-src={ require('../../assets/img/about-developer3.jpg') } alt="" className="images__second image lazyload"/>
                        </Fade>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default AboutDeveloperInfoTwo